import logo from "./images/logo.png";
import "./fonts/ge_dinar.css";
import "./fonts/bziba.css";
import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { variables } from "./variables";

function App() {
  const [formItems, setFormItems] = useState([]);
  const [pollItem, setPollItem] = useState([
    {
      pollItemId: 0,
      recordValueId: 0,
    },
  ]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const getFormItems = () => {
    axios.get(variables.API_URL + "Poll/GetPollsItems").then((response) => {
      setFormItems(response.data);
    });
  };
  const handlePoll = (Id, value) => {
    var newSelection = [...pollItem];
    if (newSelection.filter((e) => e.pollItemId === Id).length > 0) {
      for (var i = 0; i < newSelection.length; i++) {
        if (parseInt(newSelection[i].pollItemId) === parseInt(Id)) {
          newSelection[i].recordValueId = parseInt(value);
        }
      }
    } else {
      newSelection.push({
        pollItemId: Id,
        recordValueId: parseInt(value),
      });
    }
    setPollItem(newSelection.filter((x) => x.pollItemId !== 0));
  };
  const SubmitPoll = () => {
    const EmailRegex = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$");
    var validEmail = EmailRegex.test(email);
    if (name === "") {
      toast.error("Full Name is required");
    } else if(phone===""){
      toast.error("enter phone number")
    }else if (pollItem.length !== formItems.length) {
      toast.error("All fields must be filled");
    }else if(email!==""){
      if(validEmail){
        const URL = variables.API_URL + "Poll/SubmitPoll/" + formItems[0].pollId;
        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName: name,
            phonenumber:phone,
            email:email,
            pollFormRecords: pollItem,
          }),
        }).then((resp) => {
          if (resp.ok) {
            toast.success("Submited SuccessFully");
            setName("");
            setEmail("")
            setPhone("")
            setPollItem([
              {
                pollItemId: 0,
                recordValueId: 0,
              },
            ]);
          }
        });
      }else{
        toast.error("Enter a valid email")
      }
    } else{
      const URL = variables.API_URL + "Poll/SubmitPoll/" + formItems[0].pollId;
      fetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName: name,
          phonenumber:phone,
            email:email,
            pollFormRecords: pollItem,
        }),
      }).then((resp) => {
        if (resp.ok) {
          toast.success("Submited SuccessFully");
          setEmail("")
          setPhone("")
          setName("");
          setPollItem([
            {
              pollItemId: 0,
              recordValueId: 0,
            },
          ]);
        }
      });
      
    }
  };
  useEffect(() => {
    getFormItems();
  }, []);
  return (
    <div className="App">
      <img src={logo} alt="Logo" className="logo"></img>
      <div className="form-box">
        <h1 className="ff-b-bziba">أعطینی رأیک؟</h1>
        <div className="form-content">
          <div className="">
            <label>Full Name: * </label>
            <input
              placeholder="Enter Full Name"
              className="full-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <br/>
          <div className="">
            <label>Email:</label>
            <input
            type="email"
              placeholder="Enter Email"
              className="full-name"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
          <br/>
          <div className="">
            <label>Phone: * </label>
            <input
            type="phone"
              placeholder="Enter Phone Number"
              className="full-name"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></input>
          </div>
          {formItems.map((item) => {
            return (
              <div className="form-item">
                <label>{item.pollItemName}:</label>
                <ul>
                  {pollItem.filter(
                    (i) =>
                      i.pollItemId === item.pollItemId && i.pollItemId !== 0
                  ).length > 0 ? (
                    <>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">ممتاز</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          className={ pollItem.filter(
                            (i) => i.pollItemId === item.pollItemId
                          )[0].recordValueId === 4 ? "checked" : "checkmark"}
                          value={4}
                          checked={
                            pollItem.filter(
                              (i) => i.pollItemId === item.pollItemId
                            )[0].recordValueId === 4
                          }
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">جيد جدا</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          className={ pollItem.filter(
                            (i) => i.pollItemId === item.pollItemId
                          )[0].recordValueId === 3 ? "checked" : "checkmark"}
                          value={3}
                          checked={
                            pollItem.filter(
                              (i) => i.pollItemId === item.pollItemId
                            )[0].recordValueId === 3
                          }
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">جيد</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          className={ pollItem.filter(
                            (i) => i.pollItemId === item.pollItemId
                          )[0].recordValueId === 2 ? "checked" : "checkmark"}
                          value={2}
                          checked={
                            pollItem.filter(
                              (i) => i.pollItemId === item.pollItemId
                            )[0].recordValueId === 2
                          }
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">سيء</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          className={ pollItem.filter(
                            (i) => i.pollItemId === item.pollItemId
                          )[0].recordValueId === 1 ? "checked" : "checkmark"}
                          value={1}
                          checked={
                            pollItem.filter(
                              (i) => i.pollItemId === item.pollItemId
                            )[0].recordValueId === 1
                          }
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">ممتاز</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          value={4}
                          className="checkmark"
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">جيد جدا</label>
                        <input
                          type="radio"
                          className="checkmark"
                          name={item.pollItemId}
                          value={3}
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">جيد</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          value={2}
                          className="checkmark"
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                      <li>
                        {/* <span className="checkmark"></span> */}
                        <label className="ff-ge-dinar">سيء</label>
                        <input
                          type="radio"
                          name={item.pollItemId}
                          value={1}
                          className="checkmark"
                          onChange={(e) =>
                            handlePoll(item.pollItemId, e.target.value)
                          }
                        ></input>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center pb-30">
          <input
            type="submit"
            className="btn-submit"
            onClick={() => SubmitPoll()}
          />
        </div>
        <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-12 col_1 footer">
                Copyright © 2022 All Rights Reserved. Powered by{" "}
                <a href="https://vision-more.com">Vision &amp; More</a>
              </div>
            </div>
          </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
